import {
  ICheckoutsState,
  // EulogisePackageOptions,
  ICheckoutsStateAction,
  CheckoutsActionTypes,
  LeatherVideoTributeMaterial,
  LeatherVideoTributeMaterialColor,
  EulogiseLeatherVideoTributeBookOptions,
  CHECKOUTS_SHIPPING_METHOD,
  CHECKOUTS_SHIPPING_PRODUCTS,
  ADDRESS_INPUT_MODE,
  CHECKOUT_PAYMENT_METHODS,
  // EulogiseCardProducts,
  // EulogiseShippingPaperTypes,
} from '@eulogise/core'

const initialState: ICheckoutsState = {
  packageOption: null,
  isComingFromPaymentPage: false,
  willOpenThemeDrawer: false,
  isSlideshowGenerating: false,
  keepsakesDetails: {
    leatherVideoTributeBook: {
      option: null,
      metaData: {
        material: null,
        color: null,
        copyAmount: 0,
      },
      shippingMethod: CHECKOUTS_SHIPPING_METHOD.NO_SHIPPING,
    },
    shippingAddressDetails: {
      formattedAddress: null,
      isValidAddress: false,
      portalAddressMetaData: null,
      addressInputMode: ADDRESS_INPUT_MODE.NO_INPUT,
    },
  },
  billingAddressDetails: {
    formattedAddress: null,
    addressInputMode: ADDRESS_INPUT_MODE.NO_INPUT,
  },
  paymentDetails: {
    method: CHECKOUT_PAYMENT_METHODS.CREDIT_DEBIT_CARD,
  },
}

export const CheckoutsReducer = (
  state: ICheckoutsState = initialState,
  action: ICheckoutsStateAction,
): ICheckoutsState => {
  switch (action.type) {
    case CheckoutsActionTypes.UPDATE_PAYMENT_OPTION: {
      const { packageOption } = action.payload!
      return {
        ...state,
        packageOption: packageOption!,
      }
    }
    case CheckoutsActionTypes.UPDATE_WILL_OPEN_THEME_DRAWER: {
      const { willOpenThemeDrawer } = action.payload!
      return {
        ...state,
        willOpenThemeDrawer: willOpenThemeDrawer!,
      }
    }
    case CheckoutsActionTypes.RESTORE_INITIAL_STATE: {
      return initialState
    }
    case CheckoutsActionTypes.UPDATE_IS_SLIDESHOW_GENERATING: {
      const { isSlideshowGenerating } = action.payload!
      return {
        ...state,
        isSlideshowGenerating: isSlideshowGenerating!,
      }
    }
    case CheckoutsActionTypes.UPDATE_LEATHER_VIDEO_TRIBUTE_BOOK_ORDER_SELECTION: {
      const { leatherVideoTributeBookOrderSelection = null } = action.payload!
      if (
        leatherVideoTributeBookOrderSelection ===
        EulogiseLeatherVideoTributeBookOptions.ORDER_LEATHER_VIDEO_TRIBUTE_BOOK
      ) {
        return {
          ...state,
          keepsakesDetails: {
            ...state.keepsakesDetails,
            leatherVideoTributeBook: {
              ...state.keepsakesDetails.leatherVideoTributeBook,
              option:
                EulogiseLeatherVideoTributeBookOptions.ORDER_LEATHER_VIDEO_TRIBUTE_BOOK,
              metaData: {
                ...state.keepsakesDetails.leatherVideoTributeBook.metaData,
                material: LeatherVideoTributeMaterial.LEATHER,
                color: LeatherVideoTributeMaterialColor.WHITE,
                copyAmount: 1,
              },
            },
          },
        }
      } else {
        return {
          ...state,
          keepsakesDetails: {
            ...state.keepsakesDetails,
            leatherVideoTributeBook: {
              ...initialState.keepsakesDetails.leatherVideoTributeBook,
              metaData: {
                ...initialState.keepsakesDetails.leatherVideoTributeBook
                  .metaData,
              },
              option: leatherVideoTributeBookOrderSelection,
            },
          },
        }
      }
    }
    case CheckoutsActionTypes.UPDATE_LEATHER_TRIBUTE_SHIPPING_METHOD: {
      const { shippingMethod } = action.payload!
      if (shippingMethod) {
        return {
          ...state,
          keepsakesDetails: {
            ...state.keepsakesDetails,
            leatherVideoTributeBook: {
              ...state.keepsakesDetails.leatherVideoTributeBook,
              shippingMethod,
            },
          },
        }
      }
      return state
    }
    case CheckoutsActionTypes.UPDATE_KEEPSAKES_SHIPPING_ADDRESS_DETAILS: {
      const { keepsakesShippingAddressDetails } = action.payload!
      if (keepsakesShippingAddressDetails) {
        return {
          ...state,
          keepsakesDetails: {
            ...state.keepsakesDetails,
            shippingAddressDetails: keepsakesShippingAddressDetails,
          },
        }
      }
      return state
    }
    case CheckoutsActionTypes.UPDATE_BILLING_ADDRESS_DETAILS: {
      const { billingAddressDetails } = action.payload!
      if (billingAddressDetails) {
        return {
          ...state,
          billingAddressDetails,
        }
      }
      return state
    }
    case CheckoutsActionTypes.UPDATE_LEATHER_VIDEO_TRIBUTE_BOOK_DETAILS: {
      const { leatherVideoTributeBookDetails } = action.payload!
      if (leatherVideoTributeBookDetails) {
        return {
          ...state,
          keepsakesDetails: {
            ...state.keepsakesDetails,
            leatherVideoTributeBook: {
              ...state.keepsakesDetails.leatherVideoTributeBook,
              metaData: {
                ...state.keepsakesDetails.leatherVideoTributeBook.metaData,
                ...leatherVideoTributeBookDetails,
              },
            },
          },
        }
      }
      return state
    }
    case CheckoutsActionTypes.UPDATE_PAYMENT_DETAILS: {
      const { paymentDetails } = action.payload!
      if (paymentDetails) {
        return {
          ...state,
          paymentDetails,
        }
      }
      return state
    }
    // case CheckoutsActionTypes.UPDATE_PRODUCT_PAPER_TYPE: {
    //   const { product, paperType } = action.payload!
    //   if (product && paperType) {
    //     return {
    //       ...state,
    //       shippingDetails: {
    //         ...state.shippingDetails,
    //         [product]: {
    //           ...state.shippingDetails[product],
    //           paperType,
    //         },
    //       },
    //     }
    //   }
    // }
    // case CheckoutsActionTypes.UPDATE_PRODUCT_COPIES_AMOUNT: {
    //   const { product, copiesAmount } = action.payload!
    //   if (product && copiesAmount) {
    //     return {
    //       ...state,
    //       shippingDetails: {
    //         ...state.shippingDetails,
    //         [product]: {
    //           ...state.shippingDetails[product],
    //           copiesAmount,
    //         },
    //       },
    //     }
    //   }
    // }
    // case CheckoutsActionTypes.UPDATE_IS_COMING_FROM_PAYMENT_PAGE: {
    //   const { isComingFromPaymentPage } = action.payload!
    //   return {
    //     ...state,
    //     isComingFromPaymentPage: isComingFromPaymentPage!,
    //   }
    // }
    default:
      return state
  }
}

export const CheckoutsInitialState = initialState
