import {
  CHECKOUTS_SHIPPING_METHOD,
  CHECKOUTS_SHIPPING_PRODUCTS,
  EulogiseCountry,
  EulogisePackageOptions,
  EULOGIZE_SHIPPING_FEE_MATRIX,
  EulogiseLeatherVideoTributeBookOptions,
  EulogisePage,
  EulogizeShippingAvailableCountries,
  EulogiseCardProducts,
  EulogiseProduct,
  ICardProductData,
  ICheckoutTributeMetaData,
  ICheckoutTributeMetaDataPayload,
  ISlideshowData,
  MemorialVisualStatus,
  ResourceFileStatus,
  EULOGIZE_CHECKOUT_PACKAGE_OPTION,
  IInvoice,
  EulogisePackageOptionTypes,
  EulogisePurchaseInformationPackageName,
  EulogisePurchaseInformationShippingMethodName,
} from '@eulogise/core'

import { NavigationHelper } from './NavigationHelper'
import { EulogiseClientConfig } from '@eulogise/client-core'

export class CheckoutHelper {
  public static getPackagePriceByCountry({
    country,
    packageOption,
  }: {
    country: EulogiseCountry
    packageOption: EulogisePackageOptions | null
  }) {
    switch (country) {
      // Charge with AUD $149
      case EulogiseCountry.AUSTRALIA:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 149
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 99
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 99
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 50
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 50
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with GBP £79
      case EulogiseCountry.UNITED_KINGDOM:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 79
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 54
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 54
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 25
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 25
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with EURO €94
      case EulogiseCountry.EUROPEAN_UNION:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 94
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 64
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 64
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 30
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 30
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with CAD $149
      case EulogiseCountry.CANADA:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 149
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 99
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 99
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 30
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 30
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with NZD $169
      case EulogiseCountry.NEW_ZEALAND:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 169
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 119
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 119
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 50
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 50
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with USD:
      case EulogiseCountry.UNITED_STATES:
      case EulogiseCountry.CHILE:
      case EulogiseCountry.COLOMBIA:
      case EulogiseCountry.COSTA_RICA:
      case EulogiseCountry.MEXICO:
      case EulogiseCountry.PANAMA:
      case EulogiseCountry.GUATEMALA:
      case EulogiseCountry.THE_DOMINICAN_REPUBLIC:
      case EulogiseCountry.THE_PHILIPPINES:
      case EulogiseCountry.REST_OF_THE_WOLRD:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 99
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 69
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 69
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 30
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 30
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      default:
        return 0
    }
  }

  public static getDiscountPackagePriceByCountry({
    country,
    packageOption,
  }: {
    country: EulogiseCountry
    packageOption: EulogisePackageOptions | null
  }) {
    switch (country) {
      // Charge with AUD
      case EulogiseCountry.AUSTRALIA:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 99
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 99
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 99
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 50
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 50
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with GBP
      case EulogiseCountry.UNITED_KINGDOM:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 54
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 54
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 54
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 25
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 25
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with EURO
      case EulogiseCountry.EUROPEAN_UNION:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 64
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 64
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 64
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 30
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 30
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with CAD
      case EulogiseCountry.CANADA:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 99
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 99
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 99
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 30
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 30
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with NZD
      case EulogiseCountry.NEW_ZEALAND:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 119
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 119
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 119
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 50
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 50
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      // Charge with USD:
      case EulogiseCountry.UNITED_STATES:
      case EulogiseCountry.CHILE:
      case EulogiseCountry.COLOMBIA:
      case EulogiseCountry.COSTA_RICA:
      case EulogiseCountry.MEXICO:
      case EulogiseCountry.PANAMA:
      case EulogiseCountry.GUATEMALA:
      case EulogiseCountry.THE_DOMINICAN_REPUBLIC:
      case EulogiseCountry.THE_PHILIPPINES:
      case EulogiseCountry.REST_OF_THE_WOLRD:
        switch (packageOption) {
          case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
            return 69
          case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
            return 69
          case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
            return 69
          case EulogisePackageOptions.ADD_ON_PRINTABLE:
            return 30
          case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
            return 30
          case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
            return 0
        }
      default:
        return 0
    }
  }

  public static getVideoTributeSuiteShippingFee({
    country,
    shippingMethod,
  }: {
    country: EulogiseCountry
    shippingMethod: CHECKOUTS_SHIPPING_METHOD
  }): number {
    if (
      country !== EulogiseCountry.AUSTRALIA &&
      country !== EulogiseCountry.UNITED_STATES
    ) {
      return 0
    }
    return (
      EULOGIZE_SHIPPING_FEE_MATRIX?.[
        CHECKOUTS_SHIPPING_PRODUCTS.LEATHER_VIDEO_BOOK
      ]?.[country]?.[shippingMethod] ?? 0
    )
  }

  public static getTotalPrice({
    packageFee = 0,
    leatherVideoTributeBookFee = 0,
    shippingFee = 0,
    printingFee = 0,
  }: {
    packageFee: number
    leatherVideoTributeBookFee: number
    shippingFee: number
    printingFee: number
  }) {
    return packageFee + leatherVideoTributeBookFee + shippingFee + printingFee
  }

  public static getCurrencySymbolByCountry({
    country,
  }: {
    country: EulogiseCountry
  }) {
    switch (country) {
      // US region based countries
      case EulogiseCountry.UNITED_STATES:
      case EulogiseCountry.CHILE:
      case EulogiseCountry.COLOMBIA:
      case EulogiseCountry.COSTA_RICA:
      case EulogiseCountry.MEXICO:
      case EulogiseCountry.PANAMA:
      case EulogiseCountry.GUATEMALA:
      case EulogiseCountry.THE_DOMINICAN_REPUBLIC:
      case EulogiseCountry.THE_PHILIPPINES:
      case EulogiseCountry.REST_OF_THE_WOLRD:
        return '$'
      case EulogiseCountry.CANADA:
        return '$'
      // AU Region based countries
      case EulogiseCountry.AUSTRALIA:
        return '$'
      case EulogiseCountry.NEW_ZEALAND:
        return '$'
      case EulogiseCountry.EUROPEAN_UNION:
        return '€'
      case EulogiseCountry.UNITED_KINGDOM:
        return '£'
      default:
        return '$'
    }
  }

  public static getCurrencyISOCodeByCountry({
    country,
  }: {
    country: EulogiseCountry
  }) {
    switch (country) {
      // US region based countries
      case EulogiseCountry.UNITED_STATES:
      case EulogiseCountry.CHILE:
      case EulogiseCountry.COLOMBIA:
      case EulogiseCountry.COSTA_RICA:
      case EulogiseCountry.MEXICO:
      case EulogiseCountry.PANAMA:
      case EulogiseCountry.GUATEMALA:
      case EulogiseCountry.THE_DOMINICAN_REPUBLIC:
      case EulogiseCountry.THE_PHILIPPINES:
      case EulogiseCountry.REST_OF_THE_WOLRD:
        return 'USD'
      case EulogiseCountry.CANADA:
        return 'CAD'
      // AU Region based countries
      case EulogiseCountry.AUSTRALIA:
        return 'AUD'
      case EulogiseCountry.NEW_ZEALAND:
        return 'NZD'
      case EulogiseCountry.EUROPEAN_UNION:
        return 'EUR'
      case EulogiseCountry.UNITED_KINGDOM:
        return 'GBR'
      default:
        return 'AUD'
    }
  }

  public static getStripeCurrencyCodeByCountry({
    country,
  }: {
    country: EulogiseCountry
  }) {
    switch (country) {
      // US region based countries
      case EulogiseCountry.UNITED_STATES:
      case EulogiseCountry.CHILE:
      case EulogiseCountry.COLOMBIA:
      case EulogiseCountry.COSTA_RICA:
      case EulogiseCountry.MEXICO:
      case EulogiseCountry.PANAMA:
      case EulogiseCountry.GUATEMALA:
      case EulogiseCountry.THE_DOMINICAN_REPUBLIC:
      case EulogiseCountry.THE_PHILIPPINES:
      case EulogiseCountry.REST_OF_THE_WOLRD:
        return 'usd'
      case EulogiseCountry.CANADA:
        return 'cad'
      // AU Region based countries
      case EulogiseCountry.AUSTRALIA:
        return 'aud'
      case EulogiseCountry.NEW_ZEALAND:
        return 'nzd'
      case EulogiseCountry.EUROPEAN_UNION:
        return 'eur'
      case EulogiseCountry.UNITED_KINGDOM:
        return 'gbp'
      default:
        return 'aud'
    }
  }
  public static getOnContinueFnInPackagePage({
    country,
    packageOption,
  }: {
    country: EulogiseCountry
    packageOption: EulogisePackageOptions | null
  }) {
    if (!country || !packageOption) {
      return () => null
    }
    if (!EulogizeShippingAvailableCountries.includes(country)) {
      return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_PAYMENT)
    }

    // Packages-based
    switch (packageOption) {
      // First-time checkout packages
      case EulogisePackageOptions.ALL_TRIBUTES_BUNDLE:
      case EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY:
        return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_OPTIONS)
      case EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY:
        return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_PAYMENT)
      // Add-on checkout packages
      case EulogisePackageOptions.ADD_ON_PRINTABLE:
        return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_PAYMENT)
      case EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES:
        return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_OPTIONS)
      case EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK:
        return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_OPTIONS)
      default:
        return () => null
    }
  }
  public static getOnContinueFnInOptionsPage({
    country,
    leatherVideoTributeBookOption,
  }: {
    country: EulogiseCountry
    leatherVideoTributeBookOption: EulogiseLeatherVideoTributeBookOptions | null
  }) {
    if (!country || !leatherVideoTributeBookOption) {
      return () => null
    }
    if (
      leatherVideoTributeBookOption ===
      EulogiseLeatherVideoTributeBookOptions.ORDER_LEATHER_VIDEO_TRIBUTE_BOOK
    ) {
      return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_SHIPPING)
    }
    return () => NavigationHelper.navigate(EulogisePage.CHECKOUTS_PAYMENT)
  }
  public static getLeatherVideoTributeBookFeeByCountry({
    isLeatherVideoTributeBookAdded,
    country,
  }: {
    isLeatherVideoTributeBookAdded: boolean
    country: EulogiseCountry
  }) {
    if (!isLeatherVideoTributeBookAdded || !country) {
      return 0
    }
    return 149
  }

  public static getTributeMetaData({
    allActiveCardProducts,
    slideshowData,
  }: {
    allActiveCardProducts: Record<EulogiseCardProducts, ICardProductData>
    slideshowData: ISlideshowData | undefined
  }) {
    let tributesMetaData: ICheckoutTributeMetaData = {
      [EulogiseProduct.SLIDESHOW]: {
        status: MemorialVisualStatus.NOT_STARTED,
        fileStatus: ResourceFileStatus.NOT_STARTED,
        id: null,
      },
      [EulogiseProduct.BOOKLET]: {
        status: MemorialVisualStatus.NOT_STARTED,
        fileStatus: ResourceFileStatus.NOT_STARTED,
        id: null,
      },
      [EulogiseProduct.BOOKMARK]: {
        status: MemorialVisualStatus.NOT_STARTED,
        fileStatus: ResourceFileStatus.NOT_STARTED,
        id: null,
      },
      [EulogiseProduct.SIDED_CARD]: {
        status: MemorialVisualStatus.NOT_STARTED,
        fileStatus: ResourceFileStatus.NOT_STARTED,
        id: null,
      },
      [EulogiseProduct.TV_WELCOME_SCREEN]: {
        status: MemorialVisualStatus.NOT_STARTED,
        fileStatus: ResourceFileStatus.NOT_STARTED,
        id: null,
      },
      [EulogiseProduct.THANK_YOU_CARD]: {
        status: MemorialVisualStatus.NOT_STARTED,
        fileStatus: ResourceFileStatus.NOT_STARTED,
        id: null,
      },
    }

    let cardProduct: EulogiseCardProducts
    let cardProductData: ICardProductData

    // Card Products
    // @ts-ignore:next-line
    for ([cardProduct, cardProductData] of Object.entries(
      allActiveCardProducts,
    )) {
      if (cardProduct && cardProductData) {
        const productStatus = cardProductData?.status
        for (const [_, value] of Object.entries(MemorialVisualStatus)) {
          if (value === productStatus) {
            const tributeCardProductData: ICheckoutTributeMetaDataPayload =
              tributesMetaData?.[cardProduct]!
            tributeCardProductData.status = value
            if (cardProductData?.id) {
              tributeCardProductData.id = cardProductData?.id
            }
            if (cardProductData?.fileStatus) {
              tributeCardProductData.fileStatus = cardProductData?.fileStatus
            }
          }
        }
      }
    }

    // Slideshow
    const slideshowTributeData =
      tributesMetaData.SLIDESHOW as ICheckoutTributeMetaDataPayload

    if (slideshowData && slideshowData?.id && slideshowData?.fileStatus) {
      slideshowTributeData.id = slideshowData?.id
      slideshowTributeData.status = slideshowData.status
      slideshowTributeData.fileStatus = slideshowData.fileStatus
    }
    return tributesMetaData
  }
  public static getDownloadableProducts({
    isClientCase,
    packageOption,
  }: {
    isClientCase: boolean
    packageOption: EulogisePackageOptions | null
  }): Array<EulogiseProduct> {
    const allProducts = [
      EulogiseProduct.BOOKLET,
      EulogiseProduct.BOOKMARK,
      EulogiseProduct.PHOTOBOOK,
      EulogiseProduct.SIDED_CARD,
      EulogiseProduct.SLIDESHOW,
      EulogiseProduct.THANK_YOU_CARD,
      EulogiseProduct.TV_WELCOME_SCREEN,
    ]
    if (
      isClientCase ||
      !packageOption ||
      packageOption === EulogisePackageOptions.ALL_TRIBUTES_BUNDLE
    ) {
      return allProducts
    }
    if (packageOption === EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY) {
      return (
        EULOGIZE_CHECKOUT_PACKAGE_OPTION.find(
          (p) => p.value === EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY,
        )?.packageProducts ?? []
      )
    }
    if (
      packageOption ===
      EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY
    ) {
      return (
        EULOGIZE_CHECKOUT_PACKAGE_OPTION.find(
          (p) =>
            p.value ===
            EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY,
        )?.packageProducts ?? []
      )
    }
    return allProducts
  }

  public static getClientCaseEulogizeCardAvailableForDownload = () => {
    return [
      EulogiseCardProducts.BOOKLET,
      EulogiseCardProducts.BOOKMARK,
      EulogiseCardProducts.SIDED_CARD,
      EulogiseCardProducts.THANK_YOU_CARD,
      EulogiseCardProducts.TV_WELCOME_SCREEN,
    ]
  }

  public static getAvailableCardProductsForDownloadProductsByInvoices = (
    invoices: Array<IInvoice>,
  ) => {
    if (!invoices || invoices?.length === 0) {
      return []
    }
    const purchasedPackages: Array<EulogisePackageOptions> = invoices?.map(
      (i) => i.details.packageOption,
    )
    if (
      purchasedPackages.includes(EulogisePackageOptions.ALL_TRIBUTES_BUNDLE)
    ) {
      return [
        EulogiseCardProducts.BOOKLET,
        EulogiseCardProducts.BOOKMARK,
        EulogiseCardProducts.SIDED_CARD,
        EulogiseCardProducts.THANK_YOU_CARD,
        EulogiseCardProducts.TV_WELCOME_SCREEN,
      ]
    }
    let availableCardProducts: Array<EulogiseCardProducts> = []
    if (
      purchasedPackages.includes(EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY)
    ) {
      availableCardProducts = [
        ...availableCardProducts,
        EulogiseCardProducts.BOOKLET,
        EulogiseCardProducts.BOOKMARK,
        EulogiseCardProducts.SIDED_CARD,
        EulogiseCardProducts.THANK_YOU_CARD,
      ]
    }
    if (
      purchasedPackages.includes(
        EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY,
      )
    ) {
      availableCardProducts = [
        ...availableCardProducts,
        EulogiseCardProducts.TV_WELCOME_SCREEN,
      ]
    }
    if (purchasedPackages.includes(EulogisePackageOptions.ADD_ON_PRINTABLE)) {
      availableCardProducts = [
        ...availableCardProducts,
        EulogiseCardProducts.BOOKLET,
        EulogiseCardProducts.BOOKMARK,
        EulogiseCardProducts.SIDED_CARD,
        EulogiseCardProducts.THANK_YOU_CARD,
      ]
    }
    if (
      purchasedPackages.includes(EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES)
    ) {
      availableCardProducts = [
        ...availableCardProducts,
        EulogiseCardProducts.TV_WELCOME_SCREEN,
      ]
    }
    return availableCardProducts
  }

  public static getOtherTributesAvailableToPurchaseCardProductsByInvoices = (
    invoices: Array<IInvoice>,
  ) => {
    if (!invoices || invoices?.length === 0) {
      return []
    }
    const allCardProducts = [
      EulogiseCardProducts.BOOKLET,
      EulogiseCardProducts.BOOKMARK,
      EulogiseCardProducts.SIDED_CARD,
      EulogiseCardProducts.THANK_YOU_CARD,
      EulogiseCardProducts.TV_WELCOME_SCREEN,
    ]
    const availableCardProductsForDownload =
      this.getAvailableCardProductsForDownloadProductsByInvoices(invoices)

    const otherTributesAvailableToCreateAndPurchase = allCardProducts.filter(
      (p) => !availableCardProductsForDownload.includes(p),
    )
    return otherTributesAvailableToCreateAndPurchase
  }

  public static isSlideshowGeneratable = (invoices: Array<IInvoice>) => {
    if (!invoices || invoices?.length === 0) {
      return []
    }
    const purchasedPackages: Array<EulogisePackageOptions> = invoices?.map(
      (i) => i.details.packageOption,
    )
    if (
      purchasedPackages.includes(EulogisePackageOptions.ALL_TRIBUTES_BUNDLE) ||
      purchasedPackages.includes(
        EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY,
      ) ||
      purchasedPackages.includes(EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES)
    ) {
      return true
    }
    return false
  }

  public static getDisplayedPackages({
    invoices,
    packageOptionType,
    country,
  }: {
    invoices: Array<IInvoice>
    packageOptionType: EulogisePackageOptionTypes
    country: EulogiseCountry
  }) {
    const availableCountryPackages = country
      ? EULOGIZE_CHECKOUT_PACKAGE_OPTION.filter((p) =>
          p?.appliedCountries?.includes(country),
        )
      : EULOGIZE_CHECKOUT_PACKAGE_OPTION

    if (
      !invoices ||
      invoices.length === 0 ||
      packageOptionType ===
        EulogisePackageOptionTypes.FIRST_TIME_CHECKOUT_PACKAGE
    ) {
      return availableCountryPackages.filter(
        (p) =>
          p.type === EulogisePackageOptionTypes.FIRST_TIME_CHECKOUT_PACKAGE,
      )
    } else if (
      packageOptionType === EulogisePackageOptionTypes.ADD_ON_PACKAGE
    ) {
      const addOnPackages = availableCountryPackages.filter(
        (p) => p.type === EulogisePackageOptionTypes.ADD_ON_PACKAGE,
      )
      const purchasedPackages: Array<EulogisePackageOptions> = invoices?.map(
        (i) => i.details.packageOption,
      )

      let updatedAddOnPackages = addOnPackages
      if (
        purchasedPackages.includes(EulogisePackageOptions.ALL_TRIBUTES_BUNDLE)
      ) {
        return addOnPackages.filter(
          (p) => p.value === EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK,
        )
      } else {
        if (
          purchasedPackages.includes(
            EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY,
          ) ||
          purchasedPackages.includes(
            EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES,
          )
        ) {
          updatedAddOnPackages = updatedAddOnPackages.filter(
            (addOnPackage) =>
              addOnPackage.value !==
              EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES,
          )
        }
        if (
          purchasedPackages.includes(
            EulogisePackageOptions.PRINTABLE_TRIBUTES_ONLY,
          ) ||
          purchasedPackages.includes(EulogisePackageOptions.ADD_ON_PRINTABLE)
        ) {
          updatedAddOnPackages = updatedAddOnPackages.filter(
            (addOnPackage) =>
              addOnPackage.value !== EulogisePackageOptions.ADD_ON_PRINTABLE,
          )
        }
      }
      return updatedAddOnPackages
    }
    return []
  }

  public static getPurchasePackageNameByPackageOption({
    packageOption,
  }: {
    packageOption: EulogisePackageOptions
  }) {
    return EulogisePurchaseInformationPackageName[packageOption]
  }

  public static getPurchaseShippingNameByShippingMethod({
    shippingMethod = CHECKOUTS_SHIPPING_METHOD.NO_SHIPPING,
  }: {
    shippingMethod: CHECKOUTS_SHIPPING_METHOD
  }) {
    return EulogisePurchaseInformationShippingMethodName[shippingMethod]
  }

  public static getHeaderShouldShowOption({
    packageOption,
  }: {
    packageOption: EulogisePackageOptions | null
  }) {
    if (!packageOption) {
      return false
    }
    if (
      [
        EulogisePackageOptions.ALL_TRIBUTES_BUNDLE,
        EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY,
        EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES,
        EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK,
      ].includes(packageOption)
    ) {
      return true
    }
    return false
  }

  public static getHeadershouldShowShipping({
    packageOption,
    skipShipping = false,
  }: {
    packageOption: EulogisePackageOptions | null
    skipShipping: Boolean
  }) {
    if (!packageOption) {
      return false
    }
    if (
      [
        EulogisePackageOptions.ALL_TRIBUTES_BUNDLE,
        EulogisePackageOptions.VIDEO_SLIDESHOW_AND_WELCOME_SCREEN_ONLY,
        EulogisePackageOptions.ADD_ON_VIDEO_TRIBUTES,
        EulogisePackageOptions.ADD_ON_LEATHER_VIDEO_BOOK,
      ].includes(packageOption)
    ) {
      if (!skipShipping) {
        return true
      }
      return false
    }
    return false
  }

  public static getEulogizeForeverMemorialsKeepsakes({
    onViewProduct,
  }: {
    onViewProduct: Function
  }) {
    return [
      {
        thumbnailSrc: `${`${EulogiseClientConfig.AWS_S3_URL}/assets/eulogize-tribute-sample-image-4.jpeg`}`,
        title: 'Leather Video Book',
        description: 'View product',
        isDescriptionClickable: true,
        onViewProduct,
      },
    ]
  }
}
