import { IInvoice } from '@eulogise/core'

export const InvoiceHelper = {
  sortBy: ({
    invoices,
    sortByKey,
    mode,
  }: {
    invoices: IInvoice[] | undefined
    sortByKey: keyof IInvoice
    mode: 'ASC' | 'DESC'
  }) => {
    if (!sortByKey || !invoices || !mode) {
      return invoices
    }
    if (mode === 'DESC') {
      return [...invoices].sort((a: IInvoice, b: IInvoice) =>
        a?.[sortByKey] > b?.[sortByKey] ? -1 : 1,
      )
    }
    return [...invoices].sort((a: IInvoice, b: IInvoice) =>
      a?.[sortByKey] > b?.[sortByKey] ? 1 : -1,
    )
  },
}
